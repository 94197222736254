<template>
  <div class="manual-detail manual-page-layout">
    <q-form ref="editForm">
      <c-card :title="title" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <div v-html="data.content"></div>
          </div>
        </template>
      </c-card>
      <c-card title="LBLATTACHMENT" class="cardClassDetailForm" style="margin-top:10px;padding-bottom:20px">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload-list
              :attachInfo="attachInfo"
              :editable="false"
              label="LBLATTACHMENT">
            </c-upload-list>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'manual-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysMenuId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'MANUAL_FILE',
        taskKey: '',
      },
      title: '',
      detailUrl: '',
      data: {
        sysMenuId: '',  // 메뉴 SEQ
        menuNm: '',  // 메뉴 SEQ
        content: '',  // 매뉴얼 내용
        useFlag: 'Y',  // 사용여부
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    $route(){
    }
  },
  methods: {
    init() {
      // url setting
      this.detailUrl = selectConfig.sys.manual.get.url;
      this.getData();
    },
    getData() {
      if (this.$route.query.sysMenuId) {
        this.updateMode = true;
        this.$http.url = this.$format(this.detailUrl, this.$route.query.sysMenuId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.title = '[SHE 매뉴얼] ' + this.data.menuNm;
          this.$set(this.attachInfo, 'taskKey', this.$route.query.sysMenuId)
          document.title = this.title;
        },
        () => {
        });
      }
    },
  }
};
</script>
<style lang="scss">
.manual-detail {
  padding: 10px;
}
.manual-page-layout img {
  width: 100%;
  height: auto;
}
</style>
